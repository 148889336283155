<template>
  <transition name="fade">
    <div class="c-app pdf-page">
      <loading-overlay :active="apiStateLoading" :is-full-page="true" loader="bars"/>
      <End_OperationsPdf
        :VesselSituation="pageInfo.VesselSituation"
        :itinerarySelected="pageInfo.itinerarySelected"
      />
    </div>
  </transition>
</template>

<script>
import End_OperationsPdf from "./End_OperationsPdf";
import debounce from "lodash/debounce";

//Data
function data() {
  return {
    pageInfo: {
      VesselSituation: {
        VesselSituationJson: [{
          TimeJson: [],
        }],
      },
      itinerarySelected: {},
    },
    apiStateLoading: false,
  };
}

//Created
function created() {
  let info = JSON.parse(localStorage.getItem("pageInfo"));

  if (!info) {
    window.close();
    return;
  }

  this.pageInfo = { ...this.pageInfo, ...info };
  

  localStorage.removeItem("pageInfo");

  window.addEventListener("beforeprint", function(event) {
    // document.title = `FULL CARGO PLAN`;
  });
  window.addEventListener("afterprint", function(event) {
    close();
  });
}

export default {
  name: "Pdf-Closing-Operations",
  components: {
    End_OperationsPdf,
  },
  data,
  created,
  updated: debounce(function () {
    this.$nextTick(() => {
      window.print();
    });
  }, 250),
  mounted: debounce(function () {
    this.$nextTick(() => {
      window.print();
    });
  }, 250),
};
</script>

<style scoped>
.pdf-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>